<template>
  <div class="basket-form-subscription d-flex justify-center">
    <v-checkbox v-model="isSubscribe" hide-details dense>
      <template v-slot:label>
        Согласен(-на) на получение рекламных сообщений
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'basket-form-subscription',
  props: {
    form: {}
  },
  data() {
    return {
      isSubscribe: false,
    }
  },
  watch: {
    isSubscribe(newValue) {
      if (newValue) {
        this.form.IS_SUBSCRIBE = 'Y';
      } else {
        this.form.IS_SUBSCRIBE = 'N';
      }
    }
  },
  mounted() {
    if (!this.form.IS_SUBSCRIBE || this.form.IS_SUBSCRIBE === 'N') {
      this.isSubscribe = false;
      this.form.IS_SUBSCRIBE = 'N';
    } else {
      this.isSubscribe = true;
      this.form.IS_SUBSCRIBE = 'Y';
    }
  }
}
</script>

<style lang="scss">

</style>