<template>
  <div class="basket-delivery-warning">
    <v-container>
      <transition name="fade">
        <template v-if="isWarningShown">
          <p class="basket-delivery-warning__note h5 limited mt-8 mx-auto text-center" v-html="text"></p>
        </template>
      </transition>
    </v-container>
  </div>
</template>

<script>
import {objectToArray} from "@/utils/utils";

export default {
  name: 'basket-delivery-warning',
  computed: {
    sections() {
      return this.$store.state.common.sections;
    },
    basketItems() {
      return this.$store.state.session.basket;
    },
    setting() {
      return objectToArray(this.$store.state.page.BasketController?.settings)?.find(setting => setting.CODE === 'DELIVERY_WARNING');
    },
    maxSetsCount() {
      return this.setting?.VALUE;
    },
    text() {
      return this.setting?.DETAIL_TEXT;
    },
    setsCount() {
      let count = 0;

      this.basketItems.forEach(item => {
        const itemAmount = item.AMOUNT;
        const itemSectionId = item.product.IBLOCK_SECTION_ID;
        const isItemSectionConsider = !!Number(this.sections.find(section => section.ID === itemSectionId).UF_IS_COUNT_CONSIDER);

        if (isItemSectionConsider) {
          count += itemAmount;
        }
      });

      return count;
    },
    isWarningShown() {
      return this.setsCount >= this.maxSetsCount;
    }
  }
}
</script>

<style lang="scss">
.basket-delivery-warning {
  &__note {
    border: 2px solid $golden;
    padding: 20px;
  }
}
</style>