<template>
  <div v-if="isShown" class="basket-notification">
    <v-container>
      <transition name="fade">
        <p class="basket-notification__note h5 limited mt-8 mx-auto text-center" v-html="text"></p>
      </transition>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'basket-notification',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    }
  },
  computed: {
    sections() {
      return this.$store.state.common.sections;
    },
    basketItems() {
      return this.$store.state.session.basket;
    },
  }
}
</script>

<style lang="scss">
.basket-notification {
  &__note {
    border: 2px solid $golden;
    padding: 20px;
  }
}
</style>