<template>
  <div class="basket-form-discount-card text-center">
    <h4>Желаете использовать скидочную карту?</h4>
    <v-row class="mt-2 justify-center">
      <v-col cols="12" sm="5">
        <div class="basket-form-discount-card__form d-flex align-center">
          <v-text-field v-model="form.DISCOUNT_CARD"
                        :readonly="isChecked"
                        label="Номер скидочной карты"/>
          <btn-primary :label="checkButtonText"
                       @click="onCheckButtonClick"
                       :loading="isLoading"
                       :disabled="isCheckButtonDisabled"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BtnPrimary from "@/components/btn-primary.vue";
import city from "@/mixins/city";

const TEXT_CHECK = 'Проверить';
const TEXT_CANCEL = 'Отменить';

export default {
  name: "basket-form-discount-card",
  components: {BtnPrimary},
  props: {
    form: {},
  },
  data() {
    return {
      isLoading: false,
      checkButtonText: TEXT_CHECK,
      isChecked: false,
    }
  },
  mixins: [city],
  computed: {
    isCheckButtonDisabled() {
      if (!this.form.DISCOUNT_CARD) return true;
      return this.form.DISCOUNT_CARD.length <= 0;
    },
  },
  emits: ['onSuccessfulCardCheck', 'onUnsuccessfulCardCheck', 'onCancel'],
  methods: {
    onCheckButtonClick() {
      this.isLoading = true;

      if (this.isChecked) {
        // клик на Отменить
        this.isChecked = false;
        this.form.DISCOUNT_CARD = '';
        this.form.couponDiscount = 0;
        this.form.discountBasket = null;
        this.checkButtonText = TEXT_CHECK;
        this.isLoading = false;
        this.$emit('onCancel');
        return;
      }

      this.$store.dispatch('get', {
        action: 'CheckCoupon',
        params: {
          DISCOUNT_CARD: this.form.DISCOUNT_CARD,
        },
      })
          .then((res) => {
            this.form.discountBasket = res.page?.CheckCoupon?.data?.PRICES?.BASKET;
            this.form.couponDiscount = res.page?.CheckCoupon?.data?.discount;
            this.checkButtonText = TEXT_CANCEL;
            this.isChecked = true;
            this.$emit('onSuccessfulCardCheck', res);
          })
          .catch((error) => {
            this.form.DISCOUNT_CARD = '';
            this.$emit('onUnsuccessfulCardCheck', error.message);
          })
          .finally(() => {
            this.isLoading = false;
          })
    }
  },
}
</script>

<style lang="scss">
.basket-form-discount-card {
  &__form {
    gap: 12px;
  }
}
</style>